<template>
    <div class="internal-sections">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">
                            {{ $t("message.licenseProcedureConnections") }}
                        </div>
                        <div class="filter-internal-block">
                            <crm-input
                                :size="'small'"
                                :className="'w100'"
                                v-model="filterForm.search"
                                :icon="'el-icon-search'"
                            ></crm-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <crm-create-and-column-settings
                        @c-create="drawerCreate = true"
                        :columns="columns"
                        @c-change="updateColumn"
                    >
                    </crm-create-and-column-settings>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/settings' }">
                    {{ $t("message.settings") }}
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    {{ $t("message.licenseProcedureConnections") }}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="internal-sections-table">
            <table class="table-my-code table-bordered" v-loading="loadingData">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.name.show">
                            {{ columns.name.title }}
                        </th>

                        <th v-if="columns.requirements.show">
                            {{ columns.requirements.title }}
                        </th>

                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="small"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.name.show">
                            <crm-input
                                :placeholder="columns.name.title"
                                v-model="filterForm.name"
                                size="small"
                            ></crm-input>
                        </th>

                        <th v-if="columns.requirements.show">
                            <crm-input
                                :placeholder="columns.requirements.title"
                                v-model="filterForm.name"
                                size="small"
                                :disabled="true"
                            ></crm-input>
                        </th>

                        <th v-if="columns.created_at.show">
                            <crm-date-picker
                                :placeholder="columns.created_at.title"
                                v-model="filterForm.created_at"
                            ></crm-date-picker>
                        </th>

                        <th v-if="columns.updated_at.show">
                            <crm-date-picker
                                :placeholder="columns.updated_at.title"
                                v-model="filterForm.updated_at"
                            ></crm-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="l_p_conn in list"
                        :key="l_p_conn.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">
                            {{ l_p_conn.id }}
                        </td>

                        <td v-if="columns.name.show">
                            {{ l_p_conn.name }}
                        </td>

                        <td v-if="columns.requirements.show">
                            <el-button type="text" @click="ShowItems(l_p_conn.license_requirements)">Ko'rish</el-button>
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ l_p_conn.created_at }}
                        </td>

                        <td v-if="columns.updated_at.show">
                            {{ l_p_conn.updated_at }}
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <crm-settings
                                :name="$options.name"
                                :model="l_p_conn"
                                :permissionShow="'licenseProceduresController@update'"
                                :permissionDestroy="'licenseProceduresController@destroy'"
                                :actions="actions"
                                @edit="edit"
                                @delete="destroy"
                            ></crm-settings>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my-pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                :wrapperClosable="false"
                size="70%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                ></crm-create>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                :wrapperClosable="false"
                size="70%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
            <el-dialog :title="columns.requirements.title" :visible.sync="dialogTableVisible" append-to-body>
            <el-table :data="items">
                <el-table-column property="id" :label="columns.id.title"></el-table-column>
                <el-table-column property="name" :label="columns.name.title"></el-table-column>
            </el-table>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions} from "vuex";
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
export default {
    name: "licenseProcedureConnections",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate
    },
    data() {
        return {
            dialogTableVisible: false,
            items: []
        }
    },
    computed: {
        ...mapGetters({
            list: "licenseProcedureConnections/list",
            columns: "licenseProcedureConnections/columns",
            pagination: "licenseProcedureConnections/pagination",
            statues: "licenseProcedureConnections/statues",
            filter: "licenseProcedureConnections/filter",
            sort: "licenseProcedureConnections/sort",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "licenseProcedureConnections/index",
            setPagination: "licenseProcedureConnections/setPagination",
            updateSort: "licenseProcedureConnections/updateSort",
            updateFilter: "licenseProcedureConnections/updateFilter",
            updateColumn: "licenseProcedureConnections/updateColumn",
            updatePagination: "licenseProcedureConnections/updatePagination",
            show: "licenseProcedureConnections/show",
            empty: "licenseProcedureConnections/empty",
            delete: "licenseProcedureConnections/destroy",
            refreshData: "licenseProcedureConnections/refreshData",
        }),
        ShowItems(items){
            this.dialogTableVisible = true;
            this.items = items;
        }
    },
};
</script>
