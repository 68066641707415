<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c", {
                        m: $t("message.licenseProcedureConnections"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="bodal-body-my">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item
                            :label="$t('message.nomi')"
                            prop="name"
                            class="label_mini"
                        >
                            <el-select
                                class="w-100"
                                v-model="form.license_procedure_id"
                                filterable
                                clearable
                                remote
                                :placeholder="$t('message.licensing_requirements')"
                                :remote-method="remoteMethod"
                                @change="clearData"
                                :loading="loading"
                                size="large">
                                <el-option
                                v-for="item in procedures"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            :label="$t('message.licensing_requirements')"
                            class="label_mini"
                        >
                            <el-select 
                                class="w-100"
                                v-model="form.requirements" 
                                multiple 
                                :placeholder="$t('message.licensing_requirements')" 
                                clearable>
                                <el-option
                                v-for="item in list"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    computed: {
        ...mapGetters({
            rules: "licenseProcedureConnections/rules",
            model: "licenseProcedureConnections/model",
            columns: "licenseProcedureConnections/columns",
            list: "licenseProcedures/inventory"
        }),
    },
    data(){
        return {
            procedures: []
        }
    },
    created(){
        this.licenseProcedures();
        this.fetchData();
    },
    methods: {
        ...mapActions({
            save: "licenseProcedureConnections/update",
            show: "licenseProcedureConnections/show",
            licenseProcedures: "licenseProcedures/inventory",
            remoteSearchMethod: "licenseProcedures/remoteMethod"
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        fetchData() {         
            this.show(this.selectedItem.id).then(res => {
                this.form = JSON.parse(JSON.stringify(this.model));
                if (this.form.license_procedure_id) {
                    this.remoteMethod(parseInt(this.form.license_procedure_id));
                }     
            }).catch(err => {
                  
            });   
        },
         remoteMethod(query){
            if (query) {
                this.loading = true;
                setTimeout(() => {
                    this.remoteSearchMethod({query: query})
                    .then((res) => {
                        if (res.procedures) {
                            this.loading = false;
                            this.procedures = res.procedures;
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    }, 2000);
                });
            }else{
                this.procedures = [];
            }
        },
         clearData(license_procedure_id){
            if (license_procedure_id) {
                this.procedures = [];
            }
        }
    },
};
</script>
